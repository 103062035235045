<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        style="z-index: 1; background: none; display: block; shape-rendering: auto;"
        width="40px"
        height="40px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
    >
        <rect
            x="17.5"
            y="25"
            width="15"
            height="60"
            fill="currentColor"
        >
            <animate
                attributeName="y"
                repeatCount="indefinite"
                dur="1s"
                calcMode="spline"
                keyTimes="0;0.5;1"
                values="0;25;25"
                keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
                begin="-0.2s"
            />
            <animate
                attributeName="height"
                repeatCount="indefinite"
                dur="1s"
                calcMode="spline"
                keyTimes="0;0.5;1"
                values="100;50;50"
                keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
                begin="-0.2s"
            />
        </rect>
        <rect
            x="42.5"
            y="25"
            width="15"
            height="60"
            fill="currentColor"
        >
            <animate
                attributeName="y"
                repeatCount="indefinite"
                dur="1s"
                calcMode="spline"
                keyTimes="0;0.5;1"
                values="6.25;25;25"
                keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
                begin="-0.1s"
            />
            <animate
                attributeName="height"
                repeatCount="indefinite"
                dur="1s"
                calcMode="spline"
                keyTimes="0;0.5;1"
                values="87.5;50;50"
                keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
                begin="-0.1s"
            />
        </rect>
        <rect
            x="67.5"
            y="25"
            width="15"
            height="60"
            fill="currentColor"
        >
            <animate
                attributeName="y"
                repeatCount="indefinite"
                dur="1s"
                calcMode="spline"
                keyTimes="0;0.5;1"
                values="6.25;25;25"
                keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
            />
            <animate
                attributeName="height"
                repeatCount="indefinite"
                dur="1s"
                calcMode="spline"
                keyTimes="0;0.5;1"
                values="87.5;50;50"
                keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
            />
        </rect>
        <!-- [ldio] generated by https://loading.io/ --></svg>
</template>
<script>
export default {
    name: 'PresetLoader'
}
</script>

<style lang='scss'>

svg rect {
    fill: $main-color;
}

</style>
