<template>
    <div class="">
        <div class="wrapper">
            <friends-top-bar
                :order-count="friend.batches && friend.batches.length"
                :earnings="friend.total_earnings"
            >
                <template #left_side>
                    <div class="friend-detail__info">
                        <div class="friend-detail__info-img">
                            <img
                                v-if="friend.country"
                                :src="require('@/assets/img/flags/4x3/' + friend.country.toLowerCase() + '.svg')"
                                alt=""
                            >
                            <avatar
                                v-else
                                :avatar="friend.avatar"
                                :firstname="friend.firstname"
                                :lastname="friend.lastname"
                                :email="friend.email"
                            />
                        </div>
                        <div class="friend-detail__info-about">
                            <h5 class="friend-detail__info-about__fullname">
                                {{ friend.firstname }} {{ friend.lastname }}
                            </h5>
                            <span class="friend-detail__info-about__email">{{ friend.email }}</span>
                        </div>
                    </div>
                </template>
            </friends-top-bar>
        </div>

        <no-result
            v-if="!batches.length"
            :type="'friend'"
        />
        <div v-else>
            <div
                v-if="batches.length > 0 && !dataLoading"
                class="friend-detail__content"
            >
                <div class="friend-detail__list">
                    <div
                        v-for="item in batches"
                        :key="item.id"
                        class="friend-detail__list-item"
                    >
                        <div class="friend-detail__list-item__details">
                            <div class="friend-detail__list-item__details-item">
                                <div class="friend-detail__list-item__details-item__title">
                                    Batch ID
                                </div>
                                <div class="friend-detail__list-item__details-item__val">
                                    <board-check />
                                    {{ item.batchid }}
                                </div>
                            </div>
                            <div class="friend-detail__list-item__details-item friend-detail__list-item__details-item__start">
                                <div class="friend-detail__list-item__details-item__title">
                                    Ordered: {{ item.created_at | moment_from }} ago
                                </div>
                                <div class="friend-detail__list-item__details-item__val">
                                    <clock />
                                    {{ item.created_at | moment_short_text }}
                                </div>
                            </div>
                            <div class="friend-detail__list-item__details-item">
                                <div class="friend-detail__list-item__status">
                                    {{ item.status }}
                                </div>
                            </div>
                            <div class="friend-detail__list-item__details-item friend-detail__list-item__details-item__total">
                                <div class="friend-detail__list-item__details-item__title">
                                    Total
                                </div>
                                <div class="friend-detail__list-item__details-item__val">
                                    <img
                                        src="@/assets/img/svg/walet.svg"
                                        alt=""
                                    >
                                    {{ item.total_bd | money }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Clock from 'mdi-vue/Clock';
import BoardCheck from 'mdi-vue/ClipboardCheckOutline'
import Api from '@/helpers/api/index.js';
import filtersMixin from '@/mixins/filtersMixin.js'
import NoResult from '@/components/common/NoResult';
import FriendsTopBar from '@/components/friends/FriendsTopBar.vue'
import Avatar from '@/components/common/Avatar';
import { eventBus } from '@/helpers/event-bus/'

export default {
    name: 'FriendDetail',
    components: {
        Avatar,
        NoResult,
        Clock,
        BoardCheck,
        FriendsTopBar
    },
    metaInfo() {
        return {
            title: this.friend.firstname ? `${this.friend.firstname} ${this.friend.lastname}` : this.friend.email
        }
    },
    mixins: [
        filtersMixin
    ],
    data() {
        return {
            dataLoading: true,
            friend: {},
            batches: []
        }
    },

    watch: {
    },
    async created() {
        await this.getData();
    },
    methods: {
        async getData() {
            try {
                this.dataLoading = true
                const data = await Api.get(`/api/client-friend/details/${this.$route.params.id}`)
                this.friend = data.data;
                this.batches = data.data.batches
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.dataLoading = false
            }
        }
    }
}
</script>

<style lang="scss">
    .bc-wrapper.bc-friend-details {
        min-height: 200px;
    }
    @media screen and (max-width:992px){
        .bc-wrapper.bc-friend-details {
            padding-bottom: 40px;
            .bc-wrapper_row {
                margin-top: 0;
            }
            h1 {
                margin-bottom: 14px;
            }
        }
    }
    @media screen and (max-width:768px){
        .bc-wrapper.bc-friend-details {
            padding-bottom: 40px;
            .bc-wrapper_row {
                margin-top: 0;
            }
        }
    }
    @media screen and (max-width:576px){
        .bc-wrapper.bc-horizontal.bc-wrapper_friends {
            padding-left: 5px !important;
            padding-right: 5px !important;
        }
        .bc-wrapper.bc-friend-details {
            padding-bottom: 40px;
            .bc-wrapper_row {
                margin-top: 0;
            }
            .report-wrapper {
                margin-bottom: 0;
            }
        }
    }
</style>

<style lang="scss" scoped>
    .bc-wrapper.bc-horizontal {
        padding-bottom: 20px;
        .bc-wrapper_row {
            justify-content: space-between;
            width: 100%;
        }
    }
    .friend-bar{
        display: flex;
        justify-content: space-between;
        &_details{
            display: flex;
            justify-content: flex-end;
            &__item{
                display: flex;
                align-items: center;
                &:first-child {
                    margin-right: 50px;
                }
                &-text{
                    color: #1f2939;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    .number{
                        font-size: 24px;
                        font-weight: 700;
                        margin-bottom: 4px;
                        &.earnings{
                            color: #77c93a;
                        }
                    }
                    .text{
                        color: #1f2939;
                        font-size: 18px;
                        font-weight: 400;
                    }
                }
            }
        }
        @media all and (max-width: 576px) {
            flex-direction: column;
            &_btns{
                width: 100%;
                .btn-base {
                    width: 100%;
                }
            }
            &_details{
                margin-top: 15px;
                justify-content: center;
            }
        }
    }
    .friend-detail {
        &__info {
            display: flex;
            &-img {
                width: 66px;
                height: 66px;
                border-radius: 50%;
                border: 2px solid $main-color;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            &-about {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 20px;
                color: $font-color-main;
                &__fullname {
                    font-weight: bold;
                    font-size: 22px;
                    line-height: 28px;
                    text-transform: capitalize;
                }
                &__email {
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: normal;
                }
            }
        }
        &__content {
            background: #fff;
        }
        &__list {
            width: 1200px;
            margin: auto;
            padding: 50px 0 100px 0;
            &-item {
                display: flex;
                border: 1px solid #f0f2f6;
                min-height: 110px;
                padding: 10px 0;
                background: #fff;
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
                &__status {
                    max-width: 79px;
                    padding: 11px 23px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #77c93a;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    background: rgba(89, 199, 77, 0.1);
                    border-radius: 4px;
                }
                &__details {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-grow: 2;
                    &-item {
                        display: flex;
                        flex-direction: column;
                        padding-left: 30px;
                        &:nth-child(2) {
                            flex-grow: 2;
                        }
                        &:last-child {
                            margin-right: 0;
                            padding-right: 30px;

                            .friend-detail__list-item__details-item__val {
                                width: 90px;
                            }
                        }
                        &__title {
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 20px;
                            color: #AFB2B9;
                        }
                        &__val {
                            font-weight: normal;
                            margin-top: 6px;
                            font-size: 16px;
                            line-height: 20px;
                            display: flex;
                            align-items: center;
                            svg {
                                color: $second-color;
                                margin-right: 10px;
                            }
                            img {
                                width: 17px;
                                margin-right: 10px;
                            }

                            i {
                                color: $second-color;
                                margin-right: 10px;
                            }
                        }
                    }
                }
                &__unpaid {
                    height: 110px;
                    .friend-detail__list-item__status {
                        color: $second-color;
                    }
                    .friend-detail__list-item__details-item__val {
                        color: #afb2b9;
                        img {
                            filter: grayscale(1);
                        }

                        i {
                            filter: grayscale(1);
                        }
                    }
                }
            }
        }
    }

    @media all and (max-width: 1200px) {
        .friend-detail__list {
            max-width: 960px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    @media all and (max-width: 992px) {
        .friend-detail__list-item__details {
            flex-wrap: wrap;
            padding-top: 6px;
            padding-bottom: 6px;
            &-item {
                padding-left: 21px;
                width: 50%;
                &:nth-child(3), &:nth-child(4) {
                    margin-top: 10px;
                }
            }
        }
    }

    @media all and (max-width: 768px) {
        .friend-detail__list-item {
            height: auto;
            flex-direction: column;
            padding-top: 14px;
            padding-bottom: 14px;
            &__status {
                width: 100%;
                padding: 10px 0;
            }
            &__details {
                padding: 0;
            }
        }
    }

    @media all and (max-width: 540px) {
        .friend-detail__list-item {
            &__details {
                &-item {
                    margin-top: 15px !important;
                    width: 100%;
                    &:nth-child(1) {
                        margin-top: 0 !important;
                    }
                }
            }
        }
    }
</style>
<style lang="scss">
    .friend-detail__info-img {
        display: flex;
        align-items: center;
        justify-content: center;
        .avatar-wrapper {
            height: 64px;
            width: 64px;
            .avatar-img {
                height: 64px;
                width: 64px;
                span {
                    font-size: 24px;
                }
            }
        }
    }
</style>
