<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height="512pt"
        viewBox="0 0 512 512"
        width="512pt"
        fill="#ff690c"
    >
        <path d="M452 78.668h-18.668V60c0-33.086-26.914-60-60-60h-314C26.617 0 0 26.617 0 59.332V449c0 34.738 28.262 63 63 63h389c33.086 0 60-26.914 60-60V138.668c0-33.086-26.914-60-60-60zm20 255.664h-78.332c-21.508 0-39-17.492-39-39 0-21.504 17.492-39 39-39H472zM59.332 40h314c11.027 0 20 8.973 20 20v18.668h-334C48.672 78.668 40 69.992 40 59.332 40 48.672 48.672 40 59.332 40zM452 472H63c-12.684 0-23-10.316-23-23V115.434a59.112 59.112 0 0 0 19.332 3.23H452c11.027 0 20 8.973 20 20v77.668h-78.332c-43.563 0-79 35.441-79 79 0 43.563 35.437 79 79 79H472V452c0 11.027-8.973 20-20 20zm0 0" />
    </svg>
</template>
<script>
export default {
    name: 'Walet'
}
</script>
<style scoped lang='scss'>
svg {
    width: 30px;
    height: 30px;
    margin-right: 16px;
    fill: $main-color;
}
svg path {
    stroke: $main-color;
}
</style>
